import { faq } from '@getpopsure/private-constants';
import FAQSection, { QuestionInfo, QuestionSection } from 'components/faq';
import Markdown from 'components/markdown';
import { useTranslation } from 'next-i18next';
import React from 'react';
import StrapiRichText from 'strapi/components/StrapiRichText';
import { BlockComponentProps } from 'strapi/types/utils';
import { convertBlocksToText } from 'strapi/utils/convertBlocksToText';

import styles from './FaqSection.module.scss';

export const FaqSection = ({
  block,
}: BlockComponentProps<'marketing-website.faq-section'>) => {
  const { variation, items, has_faq_page_link: hasFaqPageLink } = block;

  const { t } = useTranslation();
  let questions: QuestionSection[] = [];

  if (variation === 'default') {
    questions = [
      {
        questionInfo: items.map((item, idx) => ({
          question: item.question,
          answer: <StrapiRichText richText={item.answer} />,
          questionId: idx,
        })),
      },
    ];
  } else if (variation === 'faqWithSections') {
    const questionByCategory: Record<string, QuestionInfo[]> = {};

    items.forEach((item, idx) => {
      if (!item.category_name) throw new Error('Question category is missing');

      if (!questionByCategory[item.category_name]) {
        questionByCategory[item.category_name] = [
          {
            question: item.question,
            answer: <StrapiRichText richText={item.answer} />,
            questionId: idx,
          },
        ];
      } else {
        questionByCategory[item.category_name].push({
          question: item.question,
          answer: <StrapiRichText richText={item.answer} />,
          questionId: idx,
        });
      }
    });

    questions = Object.keys(questionByCategory).map((category) => ({
      title: category,
      questionInfo: questionByCategory[category],
    }));
  }

  const faqJsonLd = items.map(({ question, answer }) => ({
    questionName: question,
    acceptedAnswerText:
      typeof answer === 'string' ? answer : convertBlocksToText(answer),
  }));

  return (
    <section className={`mt56 p-body ${styles.container} `}>
      <FAQSection questions={questions} faqJsonLd={faqJsonLd}>
        {hasFaqPageLink && (
          <Markdown>
            {t('component.faq.faqPage.link', {
              faqLink: faq.base,
            })}
          </Markdown>
        )}
      </FAQSection>
    </section>
  );
};
